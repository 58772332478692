@import "../../../main/style.scss";

button {
	@include display-center(center, center);
	@include font($white, 20px, 700, normal, normal);
	background-color: $main;
	border: none;
	border-radius: 5px;
	box-shadow: 0px 4px 7px 0px $main;
	transition: 0.5s;

}